const site = {
  pathPrefix: '/1',
  title: '이재용의 iOS',
  author: 'jaeyong',
  description: '성장중독 개발자',
  siteUrl: 'https://www.wodyd.com/',
  github: 'wody-d',
  medium: 'wodyd',
  twitter: "jaeyong_woody",
  disqusShortName: 'gatsby-simple-blog',
  googleTrackingId: 'G-NWCBL8WPTB',
  lang: 'ko',
  displayTranslations: true,
  postsPerPage: 5,
};

const supportedLanguages = {
  ko: 'Korean',
  en: 'English',
};

module.exports = {
  site,
  supportedLanguages,
};
